import React from 'react';
import preview0 from "./imgs/projectn-rab.jpg"
import "./EngineeringWork.scss"

const EngineeringWork = () => {
    return (
        <div className="EngineeringWork">
            <h3>Проектные работы</h3>
            <img src={preview0} alt="blueprint"/>
            <p>Проектно-конструкторские работы являются неотъемлемой частью всех типов работ, связанных со строительством. Нагляднее всего это отражено в промышленном строительстве, ведь в нем важно рассмотреть техпроцесс на бумаге, чтобы иметь возможность оценить критерии безопасности и исключить все возможные ошибки еще до процесса производства</p>
            <p>Мы реализуем самые сложные и нестандартные проекты, разработка осуществляется под ключ — от концептуальной разработки до оформления документации и согласований, а также сопровождение полученного проекта.</p>
            <p>Компания «Техностиль» выполнит проектные работы, что делает возможным осуществить проект под ключ начиная с проекта до запуска объекта в работу.</p>
            <h3>Мы готовы выполнить следующие виды проектно-конструкторских работ:</h3>
            <ul>
                <p>
                    <li>промышленное освещение</li>
                    <li>промышленные автоматизированные системы</li>
                    <li>емкостное и резервуарное оборудование для нефтяных продуктов, газов и жидкостей, а также сыпучих материалов</li>
                    <li>бункера</li>
                    <li>силосы</li>
                    <li>площадки и этажерки</li>
                    <li>опоры и эстакады для технологических трубопроводов</li>
                    <li>технологические трубопроводы</li>
                    <li>градирни</li>
                    <li>водонапорные башни</li>
                    <li>галереи и эстакады</li>
                </p>
            </ul>
            <p>Высококвалифицированные сотрудники ООО «Техностиль» качественно и в срок выполнят задачи в соответствии со всеми необходимыми стандартами и нормами.</p>
            <ul>
                <p>
                    <li>Обычно большая часть проектов выполняется исходя из чертежей будущего объекта, однако если они отсутствуют, мы можем разработать их, взяв за основу документацию.</li>
                </p>
            </ul>
            <p>Также специалисты нашей компании готовы составить для вас техническое задание</p>
            <p>Качество работ подтверждается наличием лицензий на проектную деятельность.</p>
        </div>
    );
};

export default EngineeringWork;