//картинки проектов
import projects_card1 from './imgs/projects_card1.avif'
import projects_card2 from './imgs/projects_card2.avif'
import projects_card3 from './imgs/projects_card3.avif'
import projects_card4 from './imgs/projects_card4.png'
import projects_card5 from './imgs/projects_card5.png'
import projects_card6 from './imgs/projects_card6.png'
import projects_card7 from './imgs/projects_card7.png'
import projects_card8 from './imgs/projects_card8.png'
import projects_card9 from './imgs/projects_card9.png'
import projects_card10 from './imgs/projects_card10.png'
import projects_card11 from './imgs/projects_card11.png'
import projects_card12 from './imgs/projects_card12.png'
import projects_card13 from './imgs/projects_card13.png'
import projects_card14 from './imgs/projects_card14.png'
import projects_card15 from './imgs/projects_card15.png'
import projects_card16 from './imgs/projects_card16.png'
import projects_card17 from './imgs/projects_card17.png'
import projects_card18 from './imgs/projects_card18.png'
import projects_card19 from './imgs/projects_card19.png'
import projects_card20 from './imgs/projects_card20.png'
import projects_card21 from './imgs/projects_card21.png'
import projects_card22 from './imgs/projects_card22.png'
import projects_card23 from './imgs/projects_card23.png'
import projects_card24 from './imgs/projects_card24.png'
import projects_card25 from './imgs/projects_card25.png'
//картинки клиентов
import client1 from './imgs/client1.svg'
import client2 from './imgs/client2.svg'
import client3 from './imgs/client3.svg'
import client4 from './imgs/client4.svg'
import client5 from './imgs/client5.svg'
import client6 from './imgs/client6.svg'
import client7 from './imgs/client7.svg'
import client8 from './imgs/client8.svg'
import client9 from './imgs/client9.svg'
import client10 from './imgs/client10.svg'
import client11 from './imgs/client11.svg'
import client12 from './imgs/client12.svg'
import client13 from './imgs/client13.svg'
import client14 from './imgs/client14.svg'
import client15 from './imgs/client15.svg'
import client16 from './imgs/client16.svg'
import client17 from './imgs/client17.svg'
import client18 from './imgs/client18.svg'
import client19 from './imgs/client19.svg'
import client20 from './imgs/client20.svg'
//картинки услуг
import services_card1 from './imgs/services-card1.png'
import services_card2 from './imgs/services-card2.png'
import services_card3 from './imgs/services-card3.png'
import services_card4 from './imgs/services-card4.png'
import services_card5 from './imgs/services-card5.png'
import services_card6 from './imgs/services-card6.png'
import services_card7 from './imgs/services-card7.png'
import services_card8 from './imgs/services-card8.png'
import services_card9 from './imgs/services-card9.png'
import services_card10 from './imgs/services-card10.png'
import services_card11 from './imgs/services-card11.png'
import services_card12 from './imgs/services-card12.png'
import services_card13 from './imgs/services-card13.png'
import services_card14 from './imgs/services-card14.png'
//картинки направлений
import directive_card1 from './imgs/directive_card1.png'
import directive_card2 from './imgs/directive_card2.png'
import directive_card3 from './imgs/directive_card3.png'
import directive_card4 from './imgs/directive_card4.png'
import directive_card5 from './imgs/directive_card5.png'
import directive_card6 from './imgs/directive_card6.png'
import directive_card7 from './imgs/directive_card7.png'
//картинки инвестиций
import investment1 from "./imgs/investment1.avif"
import investment2 from "./imgs/investment2.avif"
import investment3 from "./imgs/investment3.avif"
import investment4 from "./imgs/investment4.avif"
import investment5 from "./imgs/investment5.avif"
import investment6 from "./imgs/investment6.avif"
import investment7 from "./imgs/investment7.avif"
//картинки услуги "Емкостное оборудование"
import CapacitiveEquipmentImg0 from "./molecules/servicesContainer/CapacitiveEquipment/imgs/1.png"
import CapacitiveEquipmentImg1 from "./molecules/servicesContainer/CapacitiveEquipment/imgs/2.png"
import CapacitiveEquipmentImg2 from "./molecules/servicesContainer/CapacitiveEquipment/imgs/3.png"
import CapacitiveEquipmentImg3 from "./molecules/servicesContainer/CapacitiveEquipment/imgs/4.png"
import CapacitiveEquipmentImg4 from "./molecules/servicesContainer/CapacitiveEquipment/imgs/5.png"
import CapacitiveEquipmentImg5 from "./molecules/servicesContainer/CapacitiveEquipment/imgs/6.png"
import CapacitiveEquipmentImg6 from "./molecules/servicesContainer/CapacitiveEquipment/imgs/7.png"
import CapacitiveEquipmentImg7 from "./molecules/servicesContainer/CapacitiveEquipment/imgs/8.png"
import CapacitiveEquipmentImg8 from "./molecules/servicesContainer/CapacitiveEquipment/imgs/9.png"
import CapacitiveEquipmentImg9 from "./molecules/servicesContainer/CapacitiveEquipment/imgs/10.png"
import CapacitiveEquipmentImg10 from "./molecules/servicesContainer/CapacitiveEquipment/imgs/11.png"
import CapacitiveEquipmentImg11 from "./molecules/servicesContainer/CapacitiveEquipment/imgs/12.png"
import CapacitiveEquipmentImg12 from "./molecules/servicesContainer/CapacitiveEquipment/imgs/13.png"
import CapacitiveEquipmentImg13 from "./molecules/servicesContainer/CapacitiveEquipment/imgs/14.png"
import CapacitiveEquipmentImg14 from "./molecules/servicesContainer/CapacitiveEquipment/imgs/15.png"
//компоненты услуг
import ProcessPipelines from "./molecules/servicesContainer/ProcessPipelines/ProcessPipelines";
import ClusterChrome from "./molecules/servicesContainer/ClusterChrome/ClusterChrome";
import CapacitiveEquipment from "./molecules/servicesContainer/CapacitiveEquipment/CapacitiveEquipment";
import WastewaterTreatment from "./molecules/servicesContainer/WastewaterTreatment/WastewaterTreatment";
import ControlSystems from "./molecules/servicesContainer/ControlSystems/ControlSystems";
import MetalWorking from "./molecules/servicesContainer/MetalWorking/MetalWorking";
import EngineeringWork from "./molecules/servicesContainer/EngineeringWork/EngineeringWork";
import ThermalInsulation from "./molecules/servicesContainer/ThermalInsulation/ThermalInsulation";
import AirDucts from "./molecules/servicesContainer/AirDucts/AirDucts";
import TechnologicalEquipment from "./molecules/servicesContainer/TechnologicalEquipment/TechnologicalEquipment";
import MetalStructures from "./molecules/servicesContainer/MetalStructures/MetalStructures";
import LoadingSystems from "./molecules/servicesContainer/LoadingSystems/LoadingSystems";
import Accessories from "./molecules/servicesContainer/Accessories/Accessories";
import ToolMaking from "./molecules/servicesContainer/ToolMaking/ToolMaking";


export const links = [
    { id: 1, title: 'услуги', link: 'services' },
    { id: 2, title: 'проекты', link: 'projects' },
    { id: 3, title: 'инновации', link: 'investments' },
    { id: 4, title: 'клиенты', link: 'clients' },
    { id: 5, title: 'партнеры', link: 'partners' },
    { id: 6, title: 'о нас', link: 'aboutUs' },
    { id: 7, title: 'контакты', link: 'contacts' },
]
export const contants_links = {
    telephone: '+7 499 999 44 99',
    adress: 'г.Самара, 2-й Безымянный переулок, дом 4а',
    mail: 'info@technosteel63.ru'
}
export const projects_cards = [
    { id: 1, title: 'Проект', src: projects_card1 },
    { id: 2, title: 'Проект', src: projects_card2 },
    { id: 3, title: 'Проект', src: projects_card3 },
    { id: 4, title: 'Коленчатые валы', src: projects_card4 },
    { id: 5, title: 'Колесо', src: projects_card5 },
    { id: 6, title: 'Проект', src: projects_card6 },
    { id: 7, title: 'Проект', src: projects_card7 },
    { id: 8, title: 'Плита-балансир', src: projects_card8 },
    { id: 9, title: 'Плита дробилки нижняя', src: projects_card9 },
    { id: 10, title: 'Плита дробилки верхняя', src: projects_card10 },
    { id:11, title: 'Проект', src: projects_card11 },
    { id: 12, title: 'Проект', src: projects_card12 },
    { id: 13, title: 'Гайки любых размеров', src: projects_card13 },
    { id: 14, title: 'Кольцо', src: projects_card14 },
    { id: 15, title: 'Проушина', src: projects_card15 },
    { id: 16, title: 'Проект', src: projects_card16 },
    { id: 17, title: 'Расточка на валу', src: projects_card17 },
    { id: 18, title: 'Проект', src: projects_card18 },
    { id: 19, title: 'Ремонт ротора', src: projects_card19 },
    { id: 20, title: 'Шайбы', src: projects_card20 },
    { id: 21, title: 'Вал-шестерня', src: projects_card21 },
    { id: 22, title: 'Вал-шестерня (ремонт)', src: projects_card22 },
    { id: 23, title: 'Валы-шестерни любой сложности', src: projects_card23 },
    { id: 24, title: 'Внутренний и наружный зуб', src: projects_card24 },
    { id: 25, title: 'Колесо-шестерня', src: projects_card25 },

]
export const investments = {
    title: 'Инвестиции',
    subtitle: 'в инновации',
    text: 'ООО “Техностиль”, благодаря собственной производственной базе и штату высококвалифицированных специалистов, имеет возможность импортозамещения и создания уникальных инновационных разработок'
}
export const our_clients = [
    { id: 1, title: 'ОДК Кузнецов', src: client1 },
    { id: 2, title: 'ЭХП Росатом', src: client2 },
    { id: 3, title: 'СТМ Сервис', src: client3 },
    { id: 4, title: 'Самолет', src: client4 },
    { id: 5, title: 'Вымпел', src: client5 },
    { id: 6, title: 'Рубин', src: client6 },
    { id: 7, title: 'НИПТБ Онега', src: client7 },
    { id: 8, title: 'ОАО Пролетарский завод', src: client8 },
    { id: 9, title: 'МССЗ', src: client9 },
    { id: 10, title: 'СЕВМАШ', src: client10 },
    { id: 11, title: 'Красный Октябрь', src: client11 },
    { id: 12, title: 'Климов', src: client12 },
    { id: 13, title: '', src: client13 },
    { id: 14, title: 'Редуктор-ПМ', src: client14 },
    { id: 15, title: 'Пермский Моторный завод', src: client15 },
    { id: 16, title: '', src: client16 },
    { id: 17, title: 'Дальневосточный завод Звезда', src: client17 },
    { id: 18, title: 'Звездочка', src: client18 },
    { id: 19, title: '', src: client19 },
    { id: 20, title: 'Адмиралтейские верфи', src: client20 },
]
export const benefits = [
    { id: 1, title: 'РАБОТАЕМ ПО ВСЕЙ РОССИИ', subtitle: 'Благодаря своему географическому расположению, на производственных площадках в г.Санкт-Петербурге и г.Самара, мы имеем возможность работы практически со всеми регионами России, обладающими потенциально высокими машиностроительными и энергетическими мощностями.' },
    { id: 2, title: 'ИНЖЕНЕРНЫЕ РЕШЕНИЯ ЗАДАЧ', subtitle: 'Высокий производственный и научно-технический потенциал, уникальный опыт, наличие высококвалифицированных кадров – все это позволяет предприятию проектировать и производить оборудование любой сложности с учетом всех требований потребителей.' },
    {
        id: 3, title: 'ВЫПОЛНЯЕМ ПРОЕКТЫ ПОД КЛЮЧ', subtitle: 'EPC/M подход к реализации проекта снимает с заказчика ряд серьезных проблем по синхронизации действий крупных и мелких подрядных и субподрядных организаций, сокращает количество контактов, и все взаимодействие по возникающим вопросам происходит с генеральным подрядчиком.',
        text: [
            { id: 1, text: 'Подготовка документации для запроса технико-коммерческого предложения.' },
            { id: 2, text: 'Проект на строительство установок, предприятий и сооружений.' },
            { id: 3, text: 'Проект организации строительства.' },
            { id: 4, text: 'Технико-экономическое обоснование инвестиций.' },
            { id: 5, text: 'Организация и проведение тендеров по выбору изготовителей.' },
            { id: 6, text: 'Определение потенциальных изготовителей.' },
        ]
    },
    { id: 4, title: 'ИННОВАЦИИ', subtitle: 'Компания тесно сотрудничает с научно-исследовательскими институтами в области обработки металлов и сплавов, а также осуществляет внедрение новейших разработок и технологий изготовления на собственном производстве.' },
    { id: 5, title: 'МЕРОПРИЯТИЯ ПО ГО И ЧС' },
    { id: 6, title: 'КОМПЛЕКСНАЯ ПОСТАВКА ОБОРУДОВАНИЯ' },
]
export const services_cards = [
    { id: 1, title: 'Проектные и конструкторские работы',src:services_card1, link: "/servicePage/1"},
    { id: 2, title: 'Технологические трубопроводы',src:services_card2, link: "/servicePage/2"},
    { id: 3, title: 'Теплоизоляция',src:services_card3, link: "/servicePage/3"},
    { id: 4, title: 'Воздуховоды',src:services_card4, link: "/servicePage/4"},
    { id: 5, title: 'Емкостное оборудование',src:services_card5, link: "/servicePage/5"},
    { id: 6, title: 'Технологическое оборудование Энергетическое оборудование',src:services_card6, link: "/servicePage/6"},
    { id: 7, title: 'Металлоконструкции',src:services_card7, link: "/servicePage/7"},
    { id: 8, title: 'Системы управления',src:services_card8, link: "/servicePage/8"},
    { id: 9, title: 'Металлообработка',src:services_card9, link: "/servicePage/9"},
    { id: 10, title: 'Системы погрузки и разгрузки вагонов',src:services_card10, link: "/servicePage/10"},
    { id: 11, title: 'Комплектующие',src:services_card11, link: "/servicePage/11"},
    { id: 12, title: 'Кластерное хромирование',src:services_card12, link: "/servicePage/12"},
    { id: 13, title: 'Очистные сооружения',src:services_card13, link: "/servicePage/13"},
    { id: 14, title: 'Изготовление инструментов',src:services_card14, link: "/servicePage/14"},
]
export const directive_cards = [
    { id: 1, title: 'машиностроение',src:directive_card1},
    { id: 2, title: 'Нефтяное, нефтехимическое и газовое оборудование',src:directive_card2, isBigCard: false},
    { id: 3, title: 'ГОРно-добывающая промышленность',src:directive_card3, isBigCard: false},
    { id: 4, title: 'судостроительный комплекс',src:directive_card4, isBigCard: false},
    { id: 5, title: 'атомная энергетика',src:directive_card5, isBigCard: false},
    { id: 6, title: 'железно-дорожное оборудование',src:directive_card6, isBigCard: false},
    { id: 7, title: 'цементная промышленность и производство бетона',src:directive_card7, isBigCard: true},
]

export const investment_cards = [
    {
        id: 1,
        title: "Дробильно-разделительный комплекс",
        content:[
            '<p>Наша организация разработала и может предложить потенциальным потребителям уникальное оборудование для утилизации железобетонных отходов</p>',
            '<p>Широкая линейка производительности этого оборудования позволяет обеспечить потребности самых разных покупателей</p>' ,
            '<p>Имеется возможность поставки в двух вариантах: стационарном и передвижном</p>' ,
            '<p>Высокие технологические возможности предлагаемой техники обеспечивают решение множества возникающих проблем.</p>',
        ],
        link: "",
        img:investment1
    },
    {
        id: 2,
        title: "Комплекс “Самара”",
        content:[
            '<p>Мы создаем оборудование для изготовления микробетона - композита из алюмосиликтного заполнителя с размером частиц до 200 микрон, тонкодисперного силикатного наполнителя, волокнистого наполнителя, контактного вяжущего гидратационного или дегидратационного типа и активных химических добавок</p>',
            '<p>Цель использования:\n' +
            '    <ul style="margin-left: 30px">\n' +
            '         <li><p>переход на новый более качественный уровень проектирования бетонов с содержанием цемента 5кг/м3 и менее на каждый МПа прочности бетона</p></li>\n' +
            '         <li><p>активное формирование оптимальной структуры цементного камня</p></li>\n' +
            '         <li><p>выход на серийное производство бетонных смесей повышенных марок</p></li>\n' +
            '         <li><p>создание цементного камня с пониженным количеством вяжущего, обладающего прочностью, морозостойкостью, водонепроницаемостью, коррозионной стойкостью  в несколько раз большей, чем обычный  отказ от использования крумного заполнителя в высокомарочных бетонах</p></li>\n' +
            '    </ul>\n' +
            '</p>',
            '<p>Комплекс можно применять в технологических линиях производства как подвижных самоуплотняющихся, так и жестких бетонных смесей для изготовления ЖБИ и конструкций, дорожного строительства, линиях производства пенобетона,и линиях производства объемно-армированных тонкостенных изделий</p>',
        ],
        link: "",
        img:investment2
    },
    {
        id: 3,
        title: "Установка “Тетра”",
        content:[
            "<p>Оборудование для управления структурой и параметрами цементного камня в бетоне путем физико-химического воздействия на цемент во время приготовления бетонной смеси.</p>",
            "<p>Цель воздействия: \t\n" +
            "Увеличение степени дисперсности цементных частиц и гомогенности цементной суспензии, регулирование процессов гидратации.</p>",
            "<p>Установка может быть использована для решения технологических задач на  всех действующих бетоносмесительных узлах, в том чиле иностранного производства.</p>",
        ],
        link: "",
        img:investment3
    },
    {
        id: 4,
        title: "Комплекс оборудования для производства биогаза",
        content:[
            "<p>Комплекс предназначен для получения биогаза  из мелкодисперсных отходов лесопиления (опилок, стружки, хвои, листьев) с последующим направлением биогаза для генерации электроэнергии с КПД 40% в газопоршневой установке</p>",
            "<p>Реализуемая комплексом технологическая схема получения биогаз</p>" +
            "    <ol style=\"margin-left: 30px\">" +
            "        <li style='font-size: 23px'><p>Органические отходы собираются и транспортером или погрузчиком засыпаются в бункер приемной емкости биогенератора</p></li>" +
            "        <li style='font-size: 23px'><p>Из этой емкости шнековым питателем материал подается на вход мельницы в которой измельчается до размеров 1 мм.</p></li>" +
            "        <li style='font-size: 23px'><p>После предварительного измельчения биомасса в необходимом объеме поступает в загрузочное устройство, где смешивается с водой и нагревается до рабочей температуры. При необходимости в нее добавляются нужные химические реагенты или биодобавки.</p></li>" +
            "        <li style='font-size: 23px'><p>Приготовленная биомасса поступает в рабочий объем биореактра в котором доизмельчается до нужного размера и постоянно перемешивается, для активного метаногенеза.</p></li>" +
            "        <li style='font-size: 23px'><p>Метан в смеси с углекислым газом постоянно выделяемый из органической биомассы откачивается насосом и сразу направляется на сжигание в газопоршневую установку</p></li>" +
            "        <li style='font-size: 23px'><p>Перед подачей в газовый электрогенератор биогаз  очищается от сероводорода и аммиака.</p></li>" +
            "        <li style='font-size: 23px'><p>Суспензия из остатков исходного сырья и микробов  удаляется насосом из реактора.</p></li>" +
            "        <li style='font-size: 23px'><p>Готовая БВС подается на вход сепаратора, в котором сгущается до влажности 60-65 %</p></li>" +
            "        <li style='font-size: 23px'><p>Сгущенная БВС направляется в накопительную емкость, в которой остывает, а горячая пульпа возвращается в биореактор.</p></li>" +
            "    </ol>" +
            ""
        ],
        link: "",
        img:investment4
    },
    {
        id: 5,
        title: "технологии получения водорода и углеводородов из метана",
        content:[
            "<p>Разработанная нами технология позволяет нам превращать природный газ (метан) в два чистых энергоносителя: окисленный водород дает воду, а сгоревшее моторное топливо дает только углекислый газ и воду.</p>",
            "<p>В настоящее время предлагаемый способ переработки природного газа является экономически целесообразным и позволяет получать два товарных продукта, рыночная цена которых выше стоимости исходного сырья.</p>" +
            "     <ul style='margin-left: 30px'>" +
            "       <li><p>Цена на метан (природный газ) в Европе составляет 23 рубля/кг</p></li>" +
            "       <li><p>Цена водорода составляет 120 рублей/кг</p></li>" +
            "       <li><p>Цена моторного топлива (бензина) составляет 180 рублей/кг</p></li>" +
            "     </ul>",
            "<p>Использование данного оборудования позволит резко повысить экологическую безопасность использования как самого природного газа, так и продуктов его переработки, а также добиться значительного экономического эффекта.</p>"
        ],
        link: "",
        img:investment5
    },
    {
        id: 6,
        title: "Установка “Волга”",
        content:[
            "<p>Установка «Волга»  - комплекс оборудования для очистки воды от тонкодисперсного и ионно-молекулярного загрязнения</p>",
            "<p>Очистка воды от органических и неорганических примесей с размером частиц менее 1 мк, а также водорастворимых солей. Установки могут быть использованы для очистки технической  и морской воды с целью получения питьевой воды. <strong>Данный комплекс не позволяет получать дистиллированную воду</strong></p>",
            "<p>В данном комплексе реализуется инновационная технология создания в объеме очищаемой жидкости частиц сорбента низкой плотности (плотность до 1 г/см3) из газа с определенными физико-химическими параметрами, последующей сорбции на активной поверхности ионов, молекул, твердых и жидких микрочастиц из объема воды и разделения очищенной воды и сорбента</p>"
        ],
        link: "",
        img:investment6
    },
    {
        id: 7,
        title: "комплексная переработка хвостов",
        content:[
            "<p>В рамках сотрудничества предлагаем поставку оборудования небольшой производительности для отработки технологии комплексной переработки ваших хвостов с целью выделения из них минеральных групп и отдельных минералов</p>",
            "<p>В состав комплекса оборудования по вашей заявке можем включить:</p>" +
            "   <ol style='margin-left: 30px'>" +
            "       <li style='font-size: 23px'><p>Оборудование для первичной подготовки исходного сырья</p></li>" +
            "       <li style='font-size: 23px'><p>Оборудование для физико-механического разделения подготовленного сырья</p></li>" +
            "       <li style='font-size: 23px'><p>Оборудование для физико-химического разделения</p></li>" +
            "       <li style='font-size: 23px'><p>Оборудование для концентрирования растворенных материалов</p></li>" +
            "   </ol>",
            "<p>Вышеназванное оборудование обеспечивает переработку исходного сырья в объеме около 100кг/час.</p>",
            "<p>После завершения лабораторных работ и выбора параметров технологии промышленной переработки хвостов мы можем разработать и изготовить оборудование для опытно–промышленных работ и отработки технологического регламента производительностью до 10 т/час</p>"
        ],
        link: "",
        img:investment7
    },
]

export const contacts = [
{id: 1, title: 'Адрес',text:'443081',text_adress:'г. Самара,',text_adress_dop:'ул. Советской Армии, 181 Офис 312'},
{id: 2, title: 'Телефоны',text:'(846) 240-82-35 (846) 33-103-83'},
{id: 3, title: 'Почта',text:'info@technosteel63.ru'},
{id: 4, title: 'Генеральный директор',text:'Вертецкий Вячеслав Александрович'},
{id: 5, title: 'Официальный представитель в москве',text:'ООО “Примерение”',text_dop:'ОГРН 1197746681384 ИНН 9702010260 +7927 606 55 81 +7903 729 95 49'},
]

export const services_menu = [
    { id: 1, title: 'Проектные и конструкторские работы',link: `/servicePage/1`,},
    { id: 2, title: 'Технологические трубопроводы',link: `/servicePage/2`,},
    { id: 3, title: 'Теплоизоляция',link: `/servicePage/3`,},
    { id: 4, title: 'Воздуховоды',link: `/servicePage/4`,},
    { id: 5, title: 'Емкостное оборудование',link: `/servicePage/5`,},
    { id: 6, title: 'Технологическое оборудование Энергетическое оборудование',link: `/servicePage/6`,},
    { id: 7, title: 'Металлоконструкции',link: `/servicePage/7`,},
    { id: 8, title: 'Системы управления',link: `/servicePage/8`,},
    { id: 9, title: 'Металлообработка',link: `/servicePage/9`,},
    { id: 10, title: 'Системы погрузки и разгрузки вагонов',link: `/servicePage/10`,},
    { id: 11, title: 'Комплектующие',link: `/servicePage/11`,},
    { id: 12, title: 'Кластерное хромирование',link: `/servicePage/12`,},
    { id: 13, title: 'Очистные сооружения',link: `/servicePage/13`,},
    { id: 14, title: 'Изготовление инструментов',link: `/servicePage/14`,},
]

 export const services_pages = [
     {id: 1, component: <EngineeringWork/>},
     {id: 2, component: <ProcessPipelines/>},
     {id: 3, component: <ThermalInsulation/>},
     {id: 4, component: <AirDucts/>},
     {id: 5, component: <CapacitiveEquipment/>},
     {id: 6, component: <TechnologicalEquipment/>},
     {id: 7, component: <MetalStructures/>},
     {id: 8, component: <ControlSystems/>},
     {id: 9, component: <MetalWorking/>},
     {id: 10, component: <LoadingSystems/>},
     {id: 11, component: <Accessories/>},
     {id: 12, component: <ClusterChrome/>},
     {id: 13, component: <WastewaterTreatment/>},
     {id: 14, component: <ToolMaking/>},
]

export const capacitiveEquipmentImgs = [
    CapacitiveEquipmentImg0,
    CapacitiveEquipmentImg1,
    CapacitiveEquipmentImg2,
    CapacitiveEquipmentImg3,
    CapacitiveEquipmentImg4,
    CapacitiveEquipmentImg5,
    CapacitiveEquipmentImg6,
    CapacitiveEquipmentImg7,
    CapacitiveEquipmentImg8,
    CapacitiveEquipmentImg9,
    CapacitiveEquipmentImg10,
    CapacitiveEquipmentImg11,
    CapacitiveEquipmentImg12,
    CapacitiveEquipmentImg13,
    CapacitiveEquipmentImg14,
]




