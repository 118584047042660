import React from 'react';
import "./ClusterChrome.scss"
import preview from "./imgs/image 28.png"

const ClusterChrome = () => {
    return (
        <div className="ClusterChrome">
            <h3>Кластерное хромирование</h3>
            <img src={preview} alt="Кластерное хромирование"/>
            <p>ООО «Техностиль» имеет возможность нанесения кластерного хромирования</p>
            <h3>Принцип действия износостойкого покрытия.</h3>
            <p>В основу технологии нанесения защитного покрытия положен способ, сущность которого заключается в изменении механизма осаждения металла, в частности хрома, за счёт введения в электролит коллоидных частиц кластерного (ультрадисперсного) наноалмаза. Кластерные материалы (наноалмазы) существенно меняют процесс гальванического осаждения металлов, что, в свою очередь, изменяет структуру износостойкого покрытия. Вследствие этого качественно улучшается адгезия хрома и достигается полное копирование микрорельефа покрываемой поверхности, что значительно увеличивает предельные напряжения сдвигового и нормального отрыва гальванического покрытия от основы.</p>
            <p>Получение АКП основано на способности наноалмазов размерами 4-6 нм соосаждаться с металлами при их электрохимическом и химическом восстановлении из растворов их солей. Это приводит к образованию двухфазного композиционного электрохимического покрытия, состоящего из металлической матрицы и внедренных в нее дисперсных частиц наноалмазов.</p>
            <h3>Наноалмазы</h3>
            <p>Используемые наноалмазы представляют собой частицы, близкие по форме к сферическим или овальным, не имеющим острых кромок. НА (наноалмазы) вообще не имеют четко очерченных граней и представляют собой сочетание одного из самых инертных и твердых веществ в природе – алмаза (ядро частицы НА) с достаточно химически активной оболочкой в виде различных функциональных групп, способных участвовать в различных химических реакциях, что приводит к появлению уникальных свойств у стандартных гальванических покрытий.</p>
            <p>В зависимости от состава электролита, выбранных подслоев и режимов нанесения возможно получение АКП с различными заданными свойствами.</p>
            <ul>
                <p>
                    <li>Такое защитное покрытие обладает повышенными триботехническими характеристиками, высокой микротвёрдостью, высокой теплопроводностью, хромированное оборудование и материалы выдерживают увеличенные динамические нагрузки при эксплуатации и отличаются повышенной коррозионной стойкостью. Ресурс такого гальванического покрытия увеличивается в 2,5 — 10 раз по сравнению с обычным твёрдым хромированием в зависимости от области использования изделия.</li>
                </p>
            </ul>
            <h3>Свойства алмазно-кластерного гальванического покрытия:</h3>
            <ul>
                <p>
                    <li>Увеличение износостойкости: в 2,5 — 5,7 раза (по сравнению с обычным твёрдым хромированием)</li>
                    <li>Микротвёрдость: до 1400 HV</li>
                    <li>Коэффициент сухого трения: до 0,09</li>
                    <li>Повышение коррозионной стойкости: в 2 — 2,5 раза (за счёт отсутствия пористости по сравнению с обычным хромированием)</li>
                    <li>Температура работы, не более: 1100°С</li>
                    <li>Толщина покрытия: 0,5 — 500 мкм.</li>
                    <li>Сцепление с материалом поверхности на уровне прочности базового металла. Нанесение осуществляется на любые углеродистые, инструментальные, штамповые стали, чугун, медь, латунь.</li>
                    <li>Сферы применения изностойкого покрытия.</li>
                </p>
            </ul>
            <ul>
                <p>
                    <li>Приоритетным для нашей компании является нанесение гальванического покрытия на элементы насосного оборудования, в частности, бурового и скважинного оборудования для нефтедобычи (детали, работающие под нагрузкой и требующие высокой износостойкости и коррозионной стойкости)</li>
                </p>
            </ul>
            <h3>Детали под нанесение:</h3>
            <ul>
                <p>
                    <li>сёдла клапанов нефтяных насосов;</li>
                    <li>роторы винтовых насосов;</li>
                    <li>опорные элементы гидрозащиты, буров и погружных электродвигателей;</li>
                    <li>внутренние поверхности корпусов поршневых гидрокомпенсаторов и гидрозащиты.</li>
                </p>
            </ul>
            <h3>Кроме того возможно нанесение защитного покрытия на следующие детали:</h3>
            <ul>
                <p>
                    <li>узлы трения машин и механизмов</li>
                    <li>детали станков-экструдеров</li>
                    <li>детали волочильного оборудования</li>
                    <li>пресс-формы</li>
                    <li>матрицы</li>
                    <li>пуансоны</li>
                    <li>свёрла</li>
                    <li>развёрстки</li>
                    <li>метчики</li>
                    <li>долбяки</li>
                    <li>резцы</li>
                    <li>металлорежущие фрезы</li>
                    <li>надфили</li>
                    <li>напильники</li>
                    <li>ножи</li>
                    <li>прочие инструменты и детали, требующие высокой износостойкости.</li>
                </p>
            </ul>
        </div>
    );
};

export default ClusterChrome;