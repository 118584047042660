import React from 'react';
import preview from "./imgs/main.png"
import "./ProcessPipelines.scss"

const ProcessPipelines = () => {
    return (
        <div className="ProcessPipelines">
            <h3>Технологические трубопроводы</h3>
            <img src={preview} alt="Технологические трубопроводы"/>
            <p>Современный условия рыночной экономики предоставляют тяжелые условий для предприятий вне зависимости от вида деятельности. Конкуренция во многих отраслях зашкаливает, что вынуждает производителей безостановочно улучшать качество выпускаемых изделий, кроме того, необходимо проводить меры по снижению издержек, чтобы иметь преимущество на рынке.</p>
            <p>Внедрение новых технологий способствует модернизации производства, что существенно экономит издержки и обеспечивает максимальное качество выпускаемой продукции.</p>
            <p>Компания «Техностиль» осуществляет проектирование, изготовление, а также монтаж и ремонт трубопроводов. Мы занимаемся как наружными, так и внутренними вне зависимости от сложности конструкции. Трубы могут быть практически любого диаметра, начиная от 6 мм, соединения разъемные и неразъемные – в зависимости от технического задания.</p>
            <ul>
                <p>
                    <li>ООО «Техностиль» располагает необходимым оборудованием для решения любых задач по монтажу, изготовлению и ремонту трубопроводов.</li>
                    <li>Мы имеем огромный опыт в области технологических трубопроводов и готовы взять на себя весь комплекс инженерных работ.</li>
                </p>
            </ul>
            <h3>Область применения</h3>
            <p>Монтаж может быть выполнен на предприятиях различных отраслей: химической и нефтехимической, пищевой. Большой выбор различных видов сталей (углеродистых, жаропрочных), меди и сплавов.</p>
            <p>Богатый арсенал оборудования компании и высокий профессионализм сотрудников позволяет применять новейшие технологии с применением всех видов сварки, что обеспечивает качество выпускаемых изделий на максимальном уровне, что по достоинству оценено Росгостехнадзором.</p>
            <ul>
                <p><li>Спроектируем и изготовим нестандартные трубопроводы ЛЮБОЙ СЛОЖНОСТИ, выполним комплексное проектирование и составление проектной документации</li></p>
            </ul>
            <p>Монтаж технологических трубопроводов осуществляется по всем стандартам и нормам, отдельное внимание направлено на технику безопасности, Вы можете быть уверены в качестве выполненных работ.</p>
            <p>Современные технологии позволят решить комплексные задачи клиента, оптимизировать бюджет на сопровождение инженерных сетей и обеспечить экономию ресурсов и энергии.</p>
        </div>
    );
};

export default ProcessPipelines;