import React from 'react';
import preview0 from "./imgs/energoobor.jpg"
import "./TechnologicalEquipment.scss"

const TechnologicalEquipment = () => {
    return (
        <div className="TechnologicalEquipment">
            <h3>Технологическое оборудование</h3>
            <img src={preview0} alt="Технологическое оборудование"/>
            <p>Основой снижения производственных издержек и повышения прибыли служит оснащение, отвечающее современным стандартам. Сегодня важно не только соответствие используемого на предприятии оснащения экологическим меркам, но и производительности и удобству использования. Все это позволяет увеличивать объемы производства и получать больше отдачи.</p>
            <ul>
                <p>
                    <li>Одним из передовых направлений компании «Техностиль» является производство технологического оборудования, а также монтаж и обслуживание изделий. Мы работаем в различных отраслях промышленности: химической, нефтехимической, а также пищевой.</li>
                </p>
            </ul>
            <h4>Заказывая изготовление технологического оснащения и оборудования у нас, вы получаете полный комплекс работ под ключ, решающий множество задач:</h4>
            <ul>
                <p>
                    <li>установка</li>
                    <li>обвязка трубопроводами</li>
                    <li>пуск и наладка</li>
                </p>
            </ul>
            <p>Таким образом, производственная линия на вашем предприятии запускается без лишней головной боли.</p>
            <h3>У нас вы можете заказать:</h3>
            <ul>
                <p>
                    <li>конвейеры</li>
                    <li>насосы</li>
                    <li>специализированное и эксклюзивное</li>
                    <li>источники бесперебойного питания</li>
                    <li>чилера</li>
                    <li>генераторы</li>
                    <li>холодильники</li>
                    <li>котлы</li>
                    <li>компрессоры</li>
                    <li>энергетическое</li>
                    <li>вентиляторы</li>
                </p>
            </ul>
            <ul>
                <p>
                    <li>
                        Осуществляя работы по монтажу и производству, мы используем только качественные материалы, а высокий профессионализм и квалификация сотрудников компании способствуют выполнению монтажа в срок и в соответствии со всеми стандартами.
                    </li>
                </p>
            </ul>
            <p>Технологическое оборудование дает очевидные плюсы производству. Помимо существенного снижения расходов на энергоресурсы также достигается повышение производительности труда.</p>
            <p>При монтаже необходимо четко соблюдать проект и монтажную карту, чтобы выполнить все требования техники безопасности и избежать неприятных последствий.</p>
            <p>Компания «Техностиль» обладает необходимым арсеналом опыта и технологий для реализации задуманных проектов.</p>
            <p>Свяжитесь с нами, если Вы не нашли необходимого оборудования в списке, либо требуется консультация специалиста.</p>
            <ul>
                <p>
                    <li>(846) 240-82-35</li>
                    <li>(846) 33-103-83</li>
                    <li>(846) 331-75-34</li>
                </p>
            </ul>
        </div>
    );
};

export default TechnologicalEquipment;