import React from 'react';
import preview0 from "./imgs/image 28.png"
import "./CapacitiveEquipment.scss"
import {capacitiveEquipmentImgs} from "../../../constants";

const CapacitiveEquipment = () => {
    return (
        <div className="CapacitiveEquipment">
            <h3>Емкостное оборудование</h3>
            <img src={preview0} alt="Емкость"/>
            <p>ООО «Техностиль» осуществит изготовление емкостного оборудования из стали, применимые для нефтепродуктов и прочих жидкостей различного объема до 50 тысяч куб. м.</p>
            <h3>Емкостное технологическое оборудование:</h3>
            <ul>
                <p>
                    <li>Силосы, предназначенных для хранения сыпучих материалов</li>
                    <li>Патрубки диаметром от 80 до 1200 мм</li>
                    <li>Рулоны стальные с защитным покрытием из полимеров (оцинкованные)</li>
                    <li>Газгольдеры из стали (100-30 000 куб. м.)</li>
                    <li>Трубопроводы для тушения пожаров емкостей различного объема (100-50 000 куб.м.)</li>
                    <li>Подогреватели для емкостей различного объема (100-50 000 куб. м.)</li>
                    <li>Изотермические емкости из стали</li>
                    <li>Емкости траншейные из стали (5000 куб. м.)</li>
                    <li>Казематные емкости из стали (100-20 000 куб. м.)</li>
                    <li>Металлические конструкции оцинкованные</li>
                    <li>Конструкции для строительства зданий и сооружений из стали</li>
                    <li>Сталь полосовая оцинкованная</li>
                </p>
            </ul>
            <h3>Преимущества производства емкостного оборудования в компании «Техностиль»</h3>
            <ul>
                <p>
                    <li>Мы производим емкости как горизонтальные, так и вертикальные, при изготовлении можно также оснастить изделия рубашкой, а также применить теплоизоляцию.</li>
                    <li>Огромный диапазон объемов емкостей, а также применение в любых отраслях промышленности, эксплуатация в самых тяжелых условиях.</li>
                    <li>Используются только самые качественные материалы: сталь (низко и высоколегированная, а также углеродистая), кроме того может быть применена полированная поверхность.</li>
                </p>
            </ul>
            <p>Помимо стандартных вариантов мы можем собрать емкости с перемешивающими приспособлениями. Такие изделия можно использовать в строительстве, химической промышленности и многих других областях деятельности. Помимо этого есть большой выбор перемешивающих приспособлений: рамные, шнековые, винтовые, зубчатые, турбинные и лопастные.</p>
            <ul>
                <p>
                    <li>Кроме производства мы также доставляем изделие на место, где будет вестись эксплуатация, проводим обвязку и подключение. Если изделие превышает допустимые нормы транспортных габаритов, составные части могут быть собраны непосредственно после доставки</li>
                </p>
            </ul>
            <p>Мы работаем на всей территории России, при необходимости произведем антикоррозийную обработку и теплоизоляцию. Примеры наших работ смотрите ниже.</p>
            <h3>Завод по производству дисперсий:</h3>
            <div className="grid">
                {capacitiveEquipmentImgs.map((img, index)=>
                    <img className="grid__item" src={img} alt="Емкостное оборудование" key={index}/>
                )}
            </div>
            <h3>Резервуарное оборудование:</h3>
            <p>Для эффективной и качественной работы с нефтепродуктами и прочими материалами большое значение имеет резервуарное оборудование, а также то, как проводится его монтаж.</p>
            <p>Данное оборудование позволяет снизить потери нефтяных продуктов и упростить техническое обслуживание резервуаров, исключить риск пожара и обеспечить надежный контроль и своевременную сигнализацию внештатных ситуаций.</p>
            <p>Компания «Техностиль» осуществляет производство резервуарного оборудования, мы имеем огромный опыт в изготовлении комплектующих под резервуары больших объемов до 50 000 кубических метров.</p>
            <p>Вы также можете заказать навесное резервуарное оборудование, включающее различные дыхательные и предохранительные клапаны, устройства для приема-раздачи и другие необходимые для эксплуатации элементы.</p>
            <p>Работаем по всей России, осуществляем доставку, сборку и монтаж оборудования на объекте.</p>
        </div>
    );
};

export default CapacitiveEquipment;