import React from 'react';
import "./MetalStructures.scss"
import preview0 from "./imgs/metallokonst1.jpg"
import preview1 from "./imgs/metallokonst.jpg"

const MetalStructures = () => {
    return (
        <div className="MetalStructures">
            <h3>Металлоконструкции</h3>
            <div>
                <img src={preview0} alt="Металлоконструкции"/>
                <img src={preview1} alt="Металлоконструкции"/>
            </div>
            <p>Сегодня в строительстве все большую популярность набирают металлоконструкции. Преимущество их использования по сравнению с другими очевидны: дешевизна, быстрый срок окупаемости, максимально сжатые сроки сборки конструкции, а также быстрый разбор при необходимости.</p>
            <ul>
                <p>
                    <li>В арсенале компании «Техностиль» все самое современное оборудование для изготовления металлоконструкций на заказ, позволяющее выполнять работы качественно и в срок.</li>
                </p>
            </ul>
            <h3>Собственный цех компании производит следующие виды продукции из металлопроката:</h3>
            <ul>
                <p>
                    <li>строительные сооружения</li>
                    <li>рамки, борта, поддоны</li>
                    <li>ригеля</li>
                    <li>косоуры</li>
                    <li>детали закладные</li>
                    <li>анкера</li>
                    <li>опалубки</li>
                    <li>перемычки</li>
                    <li>рекламные щиты, билборды</li>
                    <li>элементы технлогических трубопроводов</li>
                </p>
            </ul>
            <ul>
                <p>
                    <li>Инновационные технологии делают возможным производство высокоточных элементов, что делает максимально простым монтаж металлоконструкций и сокращает сроки изготовления металлоконструкций в разы, кроме того, на высочайшем уровне остается надежность и срок службы.</li>
                </p>
            </ul>
            <p>Мы используем современное вычислительное оборудование для точного расчета всех габаритов и параметров, проверки на прочность и сопротивление материалов. Помимо этого необходимо осуществить профессиональный монтаж, только тогда полученная металлоконструкция станет надежной и долговечной.</p>
            <p>При проектировании и производстве металлоконструкций необходимо соблюдать большое число различных требований, это не только техническое задание и пожелания заказчика, но и принятые стандарты качества.</p>
            <p>Одним из самых популярных видов металлоконструкций является металлические каркасы сварного типа. При их использовании достигается минимальная цена строительства, прочность конструкции и быстрая скорость возведения.</p>
        </div>
    );
};

export default MetalStructures;