import React from 'react';
import "./AirDucts.scss";
import preview0 from "./imgs/vozduhovody.jpg"

const AirDucts = () => {
    return (
        <div className="AirDucts">
            <h3>Воздуховоды</h3>
            <img src={preview0} alt="Воздуховоды"/>
            <p>Для обеспечения работ системы кондиционирования и вентиляции используются воздуховоды. Для работы такой системы может использоваться как один единственный элемент, так и крупная сеть элементов, позволяющих обслуживать крупный объект.</p>
            <ul>
                <p>
                    <li>Компания «Техностиль» осуществляет производство систем вентиляции различных сечений (круглых и прямоугольных) из нержавеющей либо оцинкованной сталей, а также их монтаж.</li>
                </p>
            </ul>
            <h3>Соединительные элементы</h3>
            <p>В качестве соединительных элементов используются нипели, бандажи и фланцы</p>
            <p>Фланцевый тип соединения сегодня является наиболее распространенным и часто встречающимся. Используя эту технологию, необходимо проложить уплотнение между металлическими частями, например, резину.</p>
            <h3>Типы сечений:</h3>
            <ul>
                <p>
                    <li>Круглые, производимые из стальной ленты со специальным покрытием толщиной порядка 1 мм и шириной до 130 мм, обладающим стойкостью к коррозии с фальцевым соединением (спирально-замковый тип).</li>
                    <li>Круглые (спирально-сварной тип)производятся из стальной ленты, здесь также используется специальное покрытие, стойкое к коррозии, но в данном типе толщина достигает 2,2 мм, а ширина может быть от 400 до 750 мм. Эти характеристики позволяют сваривать стыки нахлестом.</li>
                    <li>Прямоугольные. Этот тип выгодно отличается от других благодаря простоте размещения, когда высота здания небольшая. На сгибе располагается шов, что добавляет еще одно ребро жесткости, добавляя надежность конструкции</li>
                    <li>Круглые с прямым швом. Практически идентичны предыдущему типу, отличаются лишь сечением, однако это создает дополнительные преимущества, в том числе пониженное аэродинамическое сопротивление. Данный вид обладает большей прочностью и при производстве можно сэкономить до 20% материала, кроме того, они проще в изготовлении</li>
                </p>
            </ul>
            <ul>
                <p>
                    <li>
                        Наибольшей популярностью в системах кондиционирования пользуются изделия из металла, т.к. именно они отличаются максимальной прочностью и надежностью, а также обладают огнеустойчивостью.
                    </li>
                </p>
            </ul>
            <h3>Рекомендации</h3>
            <p>Прежде чем делать заказ на изготовление воздуховодов, прочтите несколько простых советов, которые помогут вам получить качественный продукт на долгое время:</p>
            <ul>
                <p>
                    <li>Низкая стоимость изделий может осуществляться за счет использования более тонкого металла с примитивной защитой от коррозии. Внимание! Толщина листа металла рассчитывается исходя из площади сечения конечного продукта. О последствиях не трудно догадаться: это и деформация конструкции, и нарушение герметизации всей вентиляционной системы в недалеком будущем.</li>
                    <li>Заказывая производство воздуховодов круглого сечения учитывайте погрешности (не существенные, но очень важные) в диаметре. Такое, как правило, возникает при заказе отдельных частей системы вентиляции. Решением станет жесткий контроль соответствия техническому заданию.</li>
                </p>
            </ul>

        </div>
    );
};

export default AirDucts;