import React from 'react';
import preview from "./imgs/image 28.png"
import table0 from "./imgs/table0.png";
import table1 from "./imgs/table1.png";
import preview1 from "./imgs/image 32.png"
import "./WastewaterTreatment.scss"

const WastewaterTreatment = () => {
    return (
        <div className="WastewaterTreatment">
            <h3>Очистные сооружения</h3>
            <img src={preview} alt="Очистные сооружения"/>
            <ul>
                <p>
                    <li>Очистка сточных вод после гальваники и лакокраски.</li>
                    <li>Проектирование и строительство очистных сооружений для машиностроительных комплексов;</li>
                    <li>Реконструкция и модернизация действующих очистных сооружений;</li>
                    <li>Разработка новых технологий очистки воды;</li>
                    <li>Разработка малоотходных технологических процессов и создание систем оборотного водоснабжения предприятий;</li>
                    <li>Очистка сточных вод гальванического производства;</li>
                    <li>Изготовление и монтаж мембранных установок ультрафильтрации и обратного осмоса;</li>
                    <li>Производство электрофлотаторов;</li>
                    <li>Изготовление и монтаж ионообменные и сорбционные фильтры;</li>
                    <li>Изготовление систем управления электроагрегатами и контроля технологических процессов;</li>
                    <li>Изготовление систем деионизации воды для гальванического производства;</li>
                    <li>Гальваническое оборудование для обработки поверхности металлов и нанесения покрытий;</li>
                    <li>Поставка и монтаж пресс фильтров и выпарных аппаратов европейского производства;</li>
                    <li>Поставка и монтаж химически стойких накопительных емкостей, систем дозирования реагентов, pH-метров, расходомеров и датчиков уровня;</li>
                    <li>Инженерный консалтинг в сфере промышленной экологии.</li>
                </p>
            </ul>
            <h3>Среднестатистические результаты очистки сточных вод гальванического производства на очистных сооружениях ООО «Техностиль»</h3>
            <img className="specificImg" src={table0} alt="table"/>
            <p>Региональные ПДК по ионам меди и кадмия не достигаются при очистке сточных вод на очистных сооружениях, построенных на основе «Наилучших Существующих Технологий» (ст. 36 Федерального закона «Об охране окружающей среды» от 10.01.2002 N 7-ФЗ).</p>
            <p>Для контроля уровня жидкости и управления насосами накопительные емкости и реактора оборудуются датчиками уровня. Для контроля расхода жидкости и управления потоками основные трубопроводы оборудуются ультразвуковыми расходомерами. Для контроля величины pH и RX накопительные емкости и реактора оборудуются датчиками pH и RX.</p>
            <p>На случай аварии на ОС предусматривается наличие дренажного приямка с погружным насосом и дополнительной накопительной емкости. В случае образования осадка в одной из накопительных емкостей или реакторе погружной насос используется для перекачивания осадка в Е5 либо непосредственно на ФП.</p>
            <ul>
                <p>
                    <li>Очистные сооружения работают как в автоматическом, так и в полуавтоматическом режиме. Система оборотного водоснабжения (мембранные установки ОО) работает в автоматическом режиме.</li>
                </p>
            </ul>
            <h3>Основное оборудование очистных сооружений:</h3>
            <p>Первым основным узлом ОС является ЭФ с нерастворимыми электродами. В ЭФ происходит выделение микропузырьков электролитических газов диаметром 10-70 мкм. Микропузырьки захватывают хлопья дисперсной фазы 20-1000 мкм и выносят их на поверхность воды, где последние накапливаются в пенном слое флотошлама. Флотошлам периодически удаляется автоматическим пеносборным устройством в сборник шлама. ЭФ обеспечивает извлечение не менее 98% дисперсных веществ от их исходного содержания.</p>
            <h3>Сравнение методов очистки сточных вод от тяжелых металлов</h3>
            <img className="specificImg" src={table1} alt="table"/>
            <ul>
                <p>
                    <li>Электрофлотаторы изготовлены из высоконадежного пластика – полипропилена, имеют нерастворимые электродные блоки ОРТА, автоматическое пеносборное устройство на базе химически стойкой полипропиленовой ткани, отличаются низким энергопотреблением – 0,25 кВт/ч*м3 очищаемой воды, не требуют обслуживания и сменных элементов, позволяют значительно экономить производственные площади.</li>
                </p>
            </ul>
            <h3>Электрофлотаторы производительностью 10 м3/час</h3>
            <p>Вторым основным узлом ОС является УФ на основе керамических мембран с размером пор 0,1-0,07 мкм. УФ работает под давлением 2-3 бар в непрерывном режиме тангенциальной фильтрации. На мембранах задерживаются практически все остаточные дисперсные вещества. Установка оборудована электромагнитными клапанами, которые управляются контроллером по таймеру. Таким образом, производится периодический сброс УФ концентрата в Р1 с последующей доочисткой в ЭФ. Для поддержания производительности УФ на заданном уровне производится периодическая продувка мембран сжатым воздухом 2-3 бар в линию фильтрата во время циркуляции жидкости в установке. Фильтрат УФ содержит только растворимые соли, такие как Na2SO4, NaCl, (NaNO3 при его наличии), и собирается в промежуточной емкости для снижения pH перед на установки ОО1 / ОО2 с целью обессоливания.</p>
            <p>Корпуса установок ультрафильтрации изготовлены из нержавеющей стали. Керамические мембранные УФ элементы имеют срок службы от 5 до 10 лет, не требуют химической регенерации и обладают повышенной химической и износостойкостью. В отличии от половолоконных полимерных мембран, керамические мембраны не подвержены зарастанию колониями микроорганизмов и необратимой адсорбции органических вещества (например флокулянтов), и, следовательно их производительность не снижается на всем периоде эксплуатации.</p>
            <h3>Установка ультрафильтрации на основе керамических мембран</h3>
            <img src={preview} alt="Очистные сооружения"/>
            <p>Третьим основным узлом ОС является двухступенчатая установка обратного осмоса ОО1 / ОО2, обеспечивающая максимальную очистку воды одновременно от анионного состава (обессоливание) и низкомолекулярных органических веществ.</p>
            <p>Каждая из установок ОО разделяет исходный поток воды на обессоленный фильтрат (пермеат) в среднем 70% и концентрат (ретентат) в среднем 30%, соответственно. Таким образом, после двухступенчатой обратноосмотической очистки до 90% воды от исходного потока возвращается на повторное использование в гальванический цех.</p>
            <p>Мембранные установки собираются на производственных площадках ООО «Техностиль» с использованием полимерных мембранных элементов ОО (RO) ведущих мировых производителей (Filmtec – Dow Chemical Company, Hydranautics — Nitto Denko Corporation) типоразмера 8040, размещенных в прочных корпусах из стеклопластика FRP. Стеклопластиковые аппараты предназначены для непрерывного, долгосрочного использования в качестве корпусов для обратноосмотических мембран и рассчитаны на давления от 10 до 80 бар для 8-дюймовых элементов.</p>
            <h3>Установка обратного осмоса на основе полимерных рулонных мембран</h3>
            <img src={preview1} alt="Очистные сооружения"/>
            <ul>
                <p>
                    <li>Полный состав и комплектация модулей для производства очистных сооружений являются коммерческой тайной организации ООО «Техностиль» .</li>
                </p>
            </ul>
        </div>
    );
};

export default WastewaterTreatment;